import { setupWorker } from 'msw'
import configHandlers from '../config/handlers'
import offerHandlers from '../offer/handlers'
import supportHandlers from '../support/handlers'
import transactionHandlers from '../transaction/handlers'

export const handlers = [
  ...configHandlers,
  ...offerHandlers,
  ...supportHandlers,
  ...transactionHandlers,
]

export const worker = setupWorker(...handlers)

// export const server = setupServer(...handlers)

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useTracking } from 'api/tracking/hooks'
import { Layout } from 'components/layout'
import useExperiment from 'hooks/useExperiment'
import { useTimer } from 'hooks/useTimer'
import Mobile from 'pages/Mobile'
import MobileTabs from 'pages/MobileTabs'
import AsyncRoute from 'preact-async-route'
import Router from 'preact-router'
import { useEffect } from 'preact/hooks'
import { useStore } from 'store/index'
import { useRouterStore } from 'store/router'
import { worker } from 'tests/mocks/server'
import 'translations/i18n'
import { UrlQuery } from 'types/url'
import { environment } from 'utils/environment'

if (['development', 'test'].includes(environment)) {
  worker.start()
}

export function App() {
  const { setQuery, setPathname } = useRouterStore()
  const { injectPixel } = useTracking()
  const { exitPixelSource } = useStore()
  const { secondsRef } = useTimer()

  const isTabs = useExperiment({
    experimentName: 'tab_switch',
    variantA: () => false,
    variantB: () => true,
  })()

  useEffect(() => {
    if (exitPixelSource !== 'none') {
      injectPixel('EXIT', {
        exit_source: exitPixelSource,
        duration: secondsRef.current,
      })
    }

    return () => {
      if (exitPixelSource === 'none') {
        injectPixel('EXIT', {
          exit_source: exitPixelSource,
          duration: secondsRef.current,
        })
      }
    }
  }, [exitPixelSource])

  function updateLocation() {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    const params = Object.fromEntries(searchParams) as unknown as UrlQuery

    setQuery(params)
    setPathname(url.pathname)
  }

  useEffect(() => {
    updateLocation()
  }, [])

  return (
    <>
      <Layout>
        <Router onChange={() => updateLocation()}>
          {isTabs ? <MobileTabs path="/mobile" /> : <Mobile path="/mobile" />}
          <AsyncRoute
            path="/status"
            getComponent={() =>
              import('./pages/Status').then((module) => module.default)
            }
          />
          <AsyncRoute
            path="/support"
            getComponent={() =>
              import('./pages/Support').then((module) => module.default)
            }
          />
          <AsyncRoute
            path="/preview"
            getComponent={() =>
              import('./pages/Preview').then((module) => module.default)
            }
          />
          <AsyncRoute
            path="/placement-preview"
            getComponent={() =>
              import('./pages/PlacementPreview').then(
                (module) => module.default
              )
            }
          />
        </Router>
      </Layout>

      {environment === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </>
  )
}

import { rest } from 'msw'
import { transactionResponse } from './data'

const transactionHandlers = [
  rest.get('*/transactions', (req, res, ctx) => {
    return res(ctx.json(transactionResponse))
  }),
]

export default transactionHandlers

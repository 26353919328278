import { useGetConfig } from 'api/config/hooks'
import { useGetOffers } from 'api/offer/hooks'
import OfferService, { PAGE_LIMIT } from 'api/offer/service'
import { Offer as OfferType } from 'api/offer/types'
import { useEventTracking, useTracking } from 'api/tracking/hooks'
import OfferHint from 'components/offer/Hint'
import OfferInfo from 'components/offer/Info'
import Onboarding from 'components/onboarding'
import Reengagement from 'components/reengagement'
import ApiErrorFallbackUI from 'components/shared/error/ApiErrorFallbackUI'
import {
  CenteredLoaderContainer,
  Loader,
  LoaderContainer,
} from 'components/shared/loader'
import NoOffers from 'components/shared/NoOffers'
import OfferRow from 'components/shared/OfferRow'
import useBannerHeight from 'hooks/useBannerHeight'
import useInfiniteScrollViewport from 'hooks/useInfiniteScrollViewport'
import useModal from 'hooks/useModal'
import useOfferModal from 'hooks/useOfferModal'
import useViewport from 'hooks/useViewport'
import { route } from 'preact-router'
import { useEffect, useState } from 'preact/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useStore } from 'store'
import { useMenuStore } from 'store/menu'
import { useRouterStore } from 'store/router'
import { Client } from 'types/url'
import { clamp } from 'utils/clamp'
import { getCookie, setCookie } from 'utils/cookies'
import { createQueryString } from 'utils/createQueryString'
import { isRunningTests } from 'utils/isRunningTests'
import rollbar from 'utils/rollbar'

interface Props {
  path: string
}

const Mobile = (_: Props) => {
  const [selectedOffer, setSelectedOffer] = useState<OfferType>()
  const [showVersionForApi, setShowVersionForApi] = useState<boolean>(false)

  const openOnboarding = useMenuStore((store) => store.openOnboarding)
  const setOpenOnboarding = useMenuStore((store) => store.setOpenOnboarding)

  const { Modal, open: openModal, close: closeModal } = useOfferModal()
  const {
    Modal: ReengagementModal,
    open: openReengagementModal,
    close: closeReengagementModal,
  } = useModal()

  const {
    Modal: OnboardingModal,
    open: openOnboardingModal,
    close: closeOnboardingModal,
  } = useModal()

  const bannerHeight = useBannerHeight()
  const { isLandscape, isTablet } = useViewport({ registerEventListener: true })
  const { scrollTopInfinite } = useInfiniteScrollViewport()
  const { query, pathname } = useRouterStore()

  const client = query['client'] as Client
  const platform = query['platform'] as string
  const appId = query['appid'] as string
  const uid = query['uid'] as string

  const {
    ofwPixelFired,
    offerATPixels,
    requestID,
    setOFWPixelFired,
    setOfferATPixels,
  } = useStore()

  const { data: config } = useGetConfig({ enabled: false })
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetched,
    isError,
  } = useGetOffers({ enabled: !!appId && !!uid })

  if (isError) {
    return (
      <ApiErrorFallbackUI
        bannerHeight={bannerHeight}
        scrollTopInfinite={scrollTopInfinite}
        isError={isError}
      />
    )
  }
  const { injectPixel } = useTracking()
  useEventTracking()

  const dataHasNoOffers = data?.pages[0].offers.length === 0
  const reengagement = data?.pages[0].reengagement

  const onAction = async (offerUrl: string) => {
    try {
      const url =
        client === 'api'
          ? selectedOffer?.offerUrl
          : await OfferService.getEarnUrl(
              `https:${offerUrl}`,
              query['platform'] as string
            )

      if (!isRunningTests()) {
        window.location.href = url
      }
    } catch (error) {
      return 'Network error'
    }
  }

  async function storeCookies() {
    if (platform === 'ios') return

    try {
      setCookie('reengagement', 'true', 1)
      await fetch('sponsorpay://exit?url=')
    } catch (error) {
      return 'Network error'
    }
  }

  async function storeOnboardingCookie() {
    try {
      const appIds = getCookie('appids')
      const uids = getCookie('uids')

      const appIdsArr = appIds?.split(',')
      const uidsArr = uids?.split(',')

      if (!appIdsArr?.includes(appId)) {
        setCookie('appids', `${appIds},${appId}`, 1)
      }

      if (!uidsArr?.includes(uid)) {
        setCookie('uids', `${uids},${uid}`, 1)
      }

      if (!appIdsArr) {
        setCookie('appids', `${appId}`, 1)
      }

      if (!uidsArr) {
        setCookie('uids', `${uid}`, 1)
      }

      await fetch('sponsorpay://exit?url=')
    } catch (error) {
      return 'Network error'
    }
  }

  useEffect(() => {
    if (isFetched && !ofwPixelFired) {
      performance.measure('OFFERS_LOADED')
      injectPixel('OFW')
      setOFWPixelFired(true)
    }
  }, [isFetched, ofwPixelFired])

  useEffect(() => {
    async function _getOffer() {
      if (!appId || !uid || !requestID || !client) {
        return
      }

      if (client === 'api') {
        try {
          const offer = await OfferService.get(query as any)

          if (Object.keys(offer).length === 0 || !offer?.offerUrl) {
            const newQuery = { ...query }
            delete newQuery['impression']
            newQuery.client = 'browser'
            route(`${pathname}${createQueryString(newQuery)}`)
            return
          }

          setSelectedOffer(offer)
          setShowVersionForApi(true)
          openModal()
        } catch (error) {
          rollbar.error(error)
        }
      }
    }

    _getOffer()
  }, [query])

  useEffect(() => {
    const isVisited = getCookie('reengagement') === 'true'
    if (reengagement?.transactions?.length > 0 && !isVisited) {
      setTimeout(() => openReengagementModal(), 500)

      storeCookies()
    }
  }, [reengagement])

  useEffect(() => {
    const hideOnboarding = getCookie('onboarding') === 'true'
    const isAppVisited =
      getCookie('appids')?.split(',')?.includes(appId) ?? false
    const hasUserVisited = getCookie('uids')?.split(',')?.includes(uid) ?? false

    if (hideOnboarding) return

    if (
      config?.showOnboarding &&
      (!isAppVisited || !hasUserVisited) &&
      client !== 'api'
    ) {
      setTimeout(() => {
        setOpenOnboarding(true)
      }, 500)

      storeOnboardingCookie()
    }
  }, [config])

  useEffect(() => {
    if (!openOnboarding) closeOnboardingModal()
    if (openOnboarding) {
      openOnboardingModal()
      injectPixel('FTUE', { page_id: 1, reason: 'onboarding' })
    }
  }, [openOnboarding])

  return (
    <>
      {!showVersionForApi && (
        // @ts-expect-error typings dont match with preact
        <InfiniteScroll
          // because OFW needs a fixed size, height = fullHeight - banner size
          height={
            document.documentElement.clientHeight -
            clamp(47, bannerHeight - scrollTopInfinite, bannerHeight)
          }
          style={{
            background: '#fff',
            paddingBottom: 16,
            marginTop: clamp(
              47,
              bannerHeight - scrollTopInfinite,
              bannerHeight
            ),
          }}
          dataLength={
            data?.pages.reduce(
              (previous, current) => previous + current.offers.length,
              0
            ) || 0
          }
          loader={
            <LoaderContainer>
              <Loader size={32} />
            </LoaderContainer>
          }
          hasMore={hasNextPage}
          next={() => fetchNextPage()}
        >
          {isLoading && (
            <CenteredLoaderContainer
              minHeight={document.documentElement.clientHeight - bannerHeight}
            >
              <LoaderContainer>
                <Loader size={32} />
              </LoaderContainer>
            </CenteredLoaderContainer>
          )}

          {config?.vcsEnabled && isFetched && <OfferHint />}

          {data?.pages.map((page, pageIndex) =>
            page.offers.map((offer, index) => (
              <OfferRow
                key={index}
                offer={offer}
                onClick={() => {
                  offer.attributes.position = pageIndex * PAGE_LIMIT + index + 1
                  setSelectedOffer(offer)
                  openModal()
                }}
                onVisible={() => {
                  offer.attributes.position = pageIndex * PAGE_LIMIT + index + 1

                  if (!offerATPixels.includes(offer.attributes.position)) {
                    injectPixel('AT', { offer })
                    setOfferATPixels(offer.attributes.position)
                  }
                }}
              />
            ))
          )}
          {dataHasNoOffers && !isFetching && (
            <NoOffers text="No offers available" />
          )}
        </InfiniteScroll>
      )}

      {platform === 'android' && (
        <ReengagementModal>
          <Reengagement
            onClose={closeReengagementModal}
            totalReward={reengagement?.totalReward}
            offers={reengagement?.transactions?.slice(
              0,
              isLandscape && !isTablet ? 3 : 5
            )}
          />
        </ReengagementModal>
      )}

      <OnboardingModal>
        <Onboarding close={closeOnboardingModal} />
      </OnboardingModal>

      <Modal
        isOfferPage={true}
        inject={!showVersionForApi}
        offer={selectedOffer}
      >
        <OfferInfo
          client={client}
          offer={selectedOffer}
          onAction={onAction}
          onClose={closeModal}
        />
      </Modal>
    </>
  )
}

export default Mobile

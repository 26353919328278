import { rest } from 'msw'
import { config } from './data'

const configHandlers = [
  rest.get('*/client_config', (req, res, ctx) => {
    const shouldInterceptConfig = localStorage.getItem('interceptNeededConfig')
    if (shouldInterceptConfig) {
      return req.passthrough()
    }
    return res(ctx.json(config))
  }),
]

export default configHandlers

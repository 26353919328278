import { Offer } from 'api/offer/types'
import { ExitPixelSource } from 'api/tracking/types'
import produce from 'immer'
import { createUUID } from 'utils/createUUID'
import { create } from 'zustand'

interface StoreState {
  reportedOffer: Offer
  transactionId: string
  ofwPixelFired: boolean
  mbePixelsFired: boolean
  offerATPixels: number[]
  tabOneOfferATPixels: number[]
  tabTwoOfferATPixels: number[]
  requestID: string | undefined
  exitPixelSource: ExitPixelSource

  setReportedOffer: (offer: Offer) => void
  setTransactionId: (id: string) => void
  setOFWPixelFired: (fired: boolean) => void
  setMBEPixelsFired: (fired: boolean) => void
  setOfferATPixels: (pixels: number) => void
  setTabOneOfferATPixels: (pixels: number) => void
  setTabTwoOfferATPixels: (pixels: number) => void
  createRequestID: () => void
  setExitPixelSource: (exitSource: ExitPixelSource) => void
}

export const useStore = create<StoreState>((set) => ({
  reportedOffer: undefined,
  transactionId: undefined,
  ofwPixelFired: false,
  mbePixelsFired: false,
  offerATPixels: [],
  tabOneOfferATPixels: [],
  tabTwoOfferATPixels: [],
  requestID: undefined,
  exitPixelSource: 'none',
  setReportedOffer: (payload: Offer) =>
    set(
      produce((draft: StoreState) => {
        draft.reportedOffer = payload
      })
    ),
  setTransactionId: (payload: string) =>
    set(
      produce((draft: StoreState) => {
        draft.transactionId = payload
      })
    ),
  setOFWPixelFired: (payload: boolean) =>
    set(
      produce((draft: StoreState) => {
        draft.ofwPixelFired = payload
      })
    ),
  setMBEPixelsFired: (payload: boolean) =>
    set(
      produce((draft: StoreState) => {
        draft.mbePixelsFired = payload
      })
    ),
  setOfferATPixels: (payload: number) =>
    set(
      produce((draft: StoreState) => {
        draft.offerATPixels.push(payload)
      })
    ),
  setTabOneOfferATPixels: (payload: number) =>
    set(
      produce((draft: StoreState) => {
        draft.tabOneOfferATPixels.push(payload)
      })
    ),
  setTabTwoOfferATPixels: (payload: number) =>
    set(
      produce((draft: StoreState) => {
        draft.tabTwoOfferATPixels.push(payload)
      })
    ),
  createRequestID: () =>
    set(
      produce((draft: StoreState) => {
        draft.requestID = createUUID()
      })
    ),
  setExitPixelSource: (payload: ExitPixelSource) =>
    set(
      produce((draft: StoreState) => {
        draft.exitPixelSource = payload
      })
    ),
}))

import { rest } from 'msw'
import { offerResponse } from './data'

const offerHandlers = [
  rest.get('*/offer', (req, res, ctx) => {
    // added let req pass without being intercepted by msw
    const shouldIntercept = localStorage.getItem('interceptNeeded')
    if (shouldIntercept) {
      return req.passthrough()
    }
    const isTabs = Number(req.url.searchParams.get('tab_1_page')) !== 0
    const limit = Number(req.url.searchParams.get('limit'))

    if (isTabs) {
      const pageTabOne = Number(req.url.searchParams.get('tab_1_page'))
      const pageTabTwo = Number(req.url.searchParams.get('tab_2_page'))
      const response = { ...offerResponse }
      response.tab_1_page = pageTabOne
      response.tab_1_pages = Math.ceil(response.tab_1_offers.length / limit)
      response.tab_1_offers = response.tab_1_offers.slice(
        (pageTabOne - 1) * limit,
        pageTabOne * limit
      )
      response.tab_2_page = pageTabTwo
      response.tab_2_pages = Math.ceil(response.tab_2_offers.length / limit)
      response.tab_2_offers = response.tab_2_offers.slice(
        (pageTabTwo - 1) * limit,
        pageTabTwo * limit
      )

      return res(ctx.json(response))
    }

    const page = Number(req.url.searchParams.get('page'))

    const response = { ...offerResponse }

    response.page = page
    response.pages = Math.ceil(response.offers.length / limit)
    response.offers = response.offers.slice((page - 1) * limit, page * limit)
    return res(ctx.json(response))
  }),
]

export default offerHandlers

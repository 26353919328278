import { useGetConfig } from 'api/config/hooks'
import { Offer as OfferType } from 'api/offer/types'
import { Carousel } from 'components/carousel'
import { ArrowDown } from 'components/offer/ArrowDown'
import Tag from 'components/tag'
import useGetCurrentPage from 'hooks/useGetCurrentPage'
import useViewport from 'hooks/useViewport'
import { memo } from 'preact/compat'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Trans, useTranslation } from 'react-i18next'
import { numericFormatter } from 'react-number-format'
import { useStore } from 'store/index'
import { Client } from 'types/url'
import { Loader } from '../../shared/loader'
import { OfferCard } from '../Card'
import { OfferHeader } from '../Header'
import { OfferInstruction, OfferInstructionItem } from '../Instruction'
import { OfferTask, OfferTaskItem } from '../Task'

interface Props {
  offer: OfferType
  client: Client
  onAction: (offerUrl: string) => void | Promise<string>
  onClose: () => void
}

const OfferInfo = memo(({ offer, client, onAction, onClose }: Props) => {
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [showIndicator, setShowIndicator] = useState<boolean>(false)

  const scrollableRef = useRef(null)

  const { currentPage } = useGetCurrentPage()

  const { setExitPixelSource } = useStore()

  const { isLandscape } = useViewport({ registerEventListener: true })
  const { data: config } = useGetConfig({ enabled: false })
  const { t } = useTranslation()

  const currency = offer.reward.currency
  const currencyValue = numericFormatter(offer.reward.amount.toString(), {
    displayType: 'text',
    thousandSeparator: true,
    decimalScale: 0,
  })

  const hasAssets = offer.assetUrls?.length > 0
  const isGrid = hasAssets ? 'landscape:grid' : 'landscape:flex'
  const modalSize = hasAssets
    ? 'landscape:md:w-[80vw]'
    : 'landscape:md:w-[512px]'
  const modalSizePortraitW = hasAssets
    ? 'portrait:md:w-[85%]'
    : 'portrait:md:w-[512px]'
  const modalSizePortraitH = hasAssets
    ? 'portrait:md:h-[85%]'
    : 'portrait:md:h-[512px]'
  const footerWidth = hasAssets
    ? `landscape:w-[calc(${document.documentElement.clientWidth}px_-_100vh)]`
    : 'landscape:w-full'

  function removeArrowDown() {
    document.querySelector('footer').style.boxShadow = 'unset'
    setShowIndicator(false)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowIndicator(
        scrollableRef.current?.scrollHeight !==
          scrollableRef.current?.offsetHeight
      )
    }, 100)

    if (currentPage === 'preview') {
      setHasError(true)
      return
    }

    return () => clearTimeout(timeout)
  }, [currentPage])

  return (
    <div
      className={`flex flex-col w-full h-full overflow-x-hidden bg-black pt-[var(--safe-area-inset-top)] ${isGrid} landscape:grid-rows-[auto_1fr_auto] landscape:pl-[var(--safe-area-inset-left)]
      md:rounded-lg md:shadow-2xl md:grid-cols-[60vh_auto] landscape:md:h-[60vh] ${modalSize} portrait:md:w-[${modalSizePortraitW} portrait:md:h-[${modalSizePortraitH}]]
      `}
      style={{
        gridTemplateAreas:
          "'carousel header' 'carousel body' 'carousel footer'",
      }}
      data-cy="offer-info"
    >
      <OfferHeader
        hasAssets={hasAssets}
        offer={offer}
        onClose={() => {
          onClose()
        }}
      />
      {isLandscape && hasAssets && <Carousel assetUrls={offer.assetUrls} />}

      <div
        className="flex flex-col flex-grow overflow-y-scroll overflow-x-hidden relative"
        style={{ gridArea: 'body' }}
        ref={scrollableRef}
        onScroll={removeArrowDown}
      >
        {!isLandscape && hasAssets && <Carousel assetUrls={offer.assetUrls} />}
        <div className="flex items-center gap-2 px-4 py-3 bg-white border-0 border-t border-solid border-gray-100 shadow-sm z-[2]">
          {offer.tags.map((tag) => (
            <Tag size="md" key={`tag-${offer.id}-${tag}`} type={tag} />
          ))}
        </div>
        <div className="flex-grow relative p-4 bg-gray-50 overflow-visible pb-[70px] landscape:md:pb-[unset]">
          <section>
            <h3 className="m-0 text-base font-semibold">
              {t('modal_instructions_text')}
            </h3>
            <OfferInstruction>
              {offer.multiPayout ||
              offer.attributes.costModel === 'cost_per_install'
                ? offer.instructions.map((instruction, i) => (
                    <OfferInstructionItem key={i} description={instruction} />
                  ))
                : offer.steps.map((step, i) => (
                    <OfferInstructionItem
                      key={i}
                      description={step.description}
                    />
                  ))}
            </OfferInstruction>
          </section>
          <section className="flex justify-around my-8">
            {offer.multiPayout && (
              <>
                <OfferCard type="task" value={offer.steps.length} />
                <div className="h-[60px] w-px bg-gray-200" />
              </>
            )}
            <OfferCard type="reward" value={offer.reward.amount} />
            <div className="h-[60px] w-px bg-gray-200" />
            <OfferCard type="time" value={offer.attributionWindow} />
          </section>
          {offer.multiPayout && (
            <section>
              <h3 className="m-0 text-base font-semibold">
                {t('modal_tasks_text')}
              </h3>
              <OfferTask>
                {offer.steps.map((step, index) => (
                  <OfferTaskItem
                    key={index}
                    currencyIcon={config?.currencyIcon}
                    completed={step.completed}
                    description={step.description}
                    reward={step.reward}
                    isMultiPayout={offer?.multiPayout}
                  />
                ))}
              </OfferTask>
            </section>
          )}
          {offer.comment && (
            <p className="w-full text-center text-gray-700 text-sm font-semibold my-4 md:text-base md:leading-5">
              {offer.comment}
            </p>
          )}
        </div>
        {showIndicator && <ArrowDown />}
      </div>

      <footer
        className={`fixed bottom-0 flex justify-center w-full px-4 py-3 bg-white z-[1] shadow-md md:relative landscape:shadow-[20px_-20px_30px_white] ${footerWidth} landscape:right-0 landscape:md:relative landscape:md:bottom-[unset] landscape:md:w-full landscape:md:shadow-[20px_-20px_30px_white]`}
        style={{ gridArea: 'footer' }}
      >
        {showLoading ? (
          <Loader size={40} />
        ) : (
          <button
            className="flex justify-center items-center w-full h-[45px] px-10 py-0 text-[18px] font-bold text-white border-none rounded-[30px] whitespace-nowrap"
            style={{
              backgroundColor: hasError
                ? 'var(--red-500)'
                : config?.colors.button_color,
              color: config?.colors.button_text_color,
            }}
            data-cy="earn-button"
            onClick={async () => {
              // button shouldn't work in placement preview
              if (currentPage === 'placementPreview') return
              if (currentPage === 'preview') return

              const url = client === 'api' ? offer.offerUrl : offer.url
              setShowLoading(true)
              setExitPixelSource('earn')
              const res = await onAction(url)
              if (res === 'Network error') {
                setShowLoading(false)
                setHasError(true)
              }
            }}
          >
            {offer.multiPayout ? (
              <Trans i18nKey="earn_up_to">
                Earn up to {{ currencyValue }} {{ currency }}
              </Trans>
            ) : (
              <Trans i18nKey="earn_text">
                Earn {{ currencyValue }} {{ currency }}
              </Trans>
            )}
          </button>
        )}
      </footer>
    </div>
  )
})

export default OfferInfo

export const ko = {
  translations: {
    action_complete_q_action: `간단한 액션`,
    action_complete_survey: `간단한 설문`,
    action_social_media: `SNS 참여`,
    action_simple_registration: `간단한 정보 등록`,
    action_discover_new: `새로운 서비스/제품 발견`,
    action_mobile_sub: `모바일 구독`,
    action_purchase: `구매 포함`,
    action_new_service: `새로운 서비스/제품 체험`,
    action_complete_action: `액션 완료`,
    action_quick_quiz: `간단한 퀴즈`,
    action_donation: `기부`,
    tags_free: `무료`,
    tags_limited_offer: `한정된 오퍼`,
    tags_multi_reward: `멀티 리워드`,
    tags_top_offer: `멀티 리워드`,
    tags_new_users: `새로 가입한 사용자 전용`,
    up_to: `{{ currency }} 까지`,
    menu_faq: `FAQ`,
    menu_offers: `제안`,
    menu_status: `상태`,
    menu_privacy: `개인정보`,
    status_pending: `대기중`,
    status_reported: `접수 완료`,
    status_expired: `만료`,
    status_rejected: `거부`,
    status_completed: `완료된 작업`,
    dtUserId: `귀하의 Digital Turbine ID는 <1>{{ digitalTurbineId }}</1> 입니다.`,
    complete_text: `완료`,
    check_inbox: `오퍼가 접수되었습니다. 이메일로 회신 내용 확인 바랍니다.`,
    contacted: `고객센터에 문의주셔서 감사합니다. 최대한 빠른 시간안에 연락드리도록 하겠습니다.`,
    description_report: `<0>사용자 지원팀에 문의해 주셔서 감사합니다. {{ currencyText }}를 수령하는 데 문제가 있는 경우 두 가지 일반적인 이유가 있습니다.</0><1>1. 제안 요구 사항 미달</1><2>앱은 제안을 완료하려면 다운로드하고 실행해야 합니다.</2><3>모든 데이터는 정확하게 그리고 진실되게 입력되어야 합니다.</3><4>대부분의 경우 이메일 주소를 확인하려면 이메일로 보내진 링크를 통해 이메일 주소를 확인해야 합니다.</4><5>많은 광고 파트너는 개인 또는 가정당 한 거래만 허용합니다.</5><6>2. 기술적인 이유</6><7>프로모션된 앱을 시작할 때 작동하는 데이터 연결이 없었습니다.</7><8>제안을 시작하기 전에 이미 프로모션된 앱이 설치되어 있었습니다.</8>`,
    problem_report: `{{ appName }}에 대한 신고`,
    problem_type1: `크레딧을 받지 못하였습니다.`,
    problem_type2: `실행중 에러 발생`,
    problem_type3: `기타`,
    email_confirmation: `이메일주소 확인 바랍니다.`,
    full_name: `성명`,
    message: `메세지`,
    submit_text: `접수`,
    onboarding_title_1: `오퍼를 완료하시고 보상을 수령하세요`,
    onboarding_title_2: `오퍼 상태 확인`,
    onboarding_title_3: `보상이 누락되었나요?`,
    offer_instructions: ``,
    offer_details: `보상이 지급 되기까진 며칠이 소유될 수 있습니다.`,
    next_text: `다음`,
    done_text: ``,
    onboarding_page2_text1: `좌측 상단에 위치한 메뉴를 열어주십시오`,
    onboarding_page2_text2: `모든 오퍼리스트 확인을 위해 "Status"를 터치 하십시오`,
    onboarding_page2_text3: `오퍼를 찾으신 후, 터치를 하시면 추가정보 확인이 가능합니다.`,
    onboarding_page3_text1: `오퍼를 찾으신 후, "Report issue"를 터치 하십시오.`,
    onboarding_page3_text2: `양식 작성후 접수를 부탁드립니다.`,
    onboarding_page3_text3: `지원팀에서 빠르게 검토후 회신드리도록 하겠습니다.`,
    reengagement_earn_up: ` <1>+{{ currencyValue }}</1> {{ currencyName }} 까지 보상을 획득하세요`,
    reengagement_description: `아직 완료하지 않은 오퍼가 존재합니다.<1></1> 아직 늦지 않았어요!`,
    reengagement_status_text: `플레이`,
    reengagement_expires1: `{{ days }} 일 남음`,
    reengagement_expires2: `{{ hours }} 시간 남음`,
    reengagement_button_text: `모든 오퍼 확인`,
    modal_reward: `총 보상`,
    modal_task: `총 Task`,
    modal_time: `완료 기한`,
    modal_tasks_completed: `완료한 Task`,
    modal_remaining_days: `남은 기간`,
    modal_tasks_text: `작업`,
    modal_instructions_text: `지침`,
    complete_offer: `제안 완료`,
    report_button_text: `문제가 있나요? 여기를 클릭하세요`,
    report_timestamp: `이 제안을 {{ reportTime }} 안에 신고할 수 있습니다`,
    menu_user_support_guide: `사용자 지원 가이드`,
    banner_support: `지원`,
    mr_text_earned: `획득한 포인트`,
    mr_text_total: `총 포인트`,
    earn_up_to: `최대 {{ currencyValue }} {{ currency }}까지 획득 가능`,
    earn_text: `{{ currencyValue }} {{ currency }} 획득`,
    up_to_text: `까지`,
    tab_1_label: `높은 급여 제안`,
    tab_2_label: `쉬운 제안`,
  },
}

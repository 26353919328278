import { getDeviceParameters } from 'utils/getDeviceParameters'
import { DataCommon, QueryOFW } from '../types'

type Data = DataCommon

export default function ofw(data: Data): QueryOFW {
  const {
    query,
    attributes,
    config,
    pagination,
    currencySale,
    offers,
    requestID,
  } = data

  const { deviceId, deviceType } = getDeviceParameters(query)

  return {
    a: query.appid,
    client: query.client,
    device_id: deviceId,
    device_id_type: deviceType,
    manufacturer: query.manufacturer,
    os_version: query.os_version,
    phone_model: query.phone_model,
    platform: query.platform,
    sdk_version: query.sdk_version,
    request_id: requestID,
    c: attributes?.country,
    i: attributes?.integration,
    l: attributes?.language,
    o: offers.map((offer) => offer.id).join(','),
    placement_id: attributes?.placementId,
    pub_app_category: attributes?.appCategory,
    request_timestamp: attributes?.requestTimestamp,
    u: attributes?.userId,
    uls: attributes?.userLifeStage,
    p: pagination.currentPage ?? 1,
    vcs_enabled: currencySale.isEnabled,
    experiments: JSON.stringify(config.activeExperiments),
    ts: Math.floor(Date.now() / 1000),
    is_ignite_user: attributes?.userHasIgnite,
  }
}

import { AssetUrl } from 'api/offer/types'
import { Pagination } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from './components/Image'
import Video from './components/Video'
import { separateAssets } from './utils'

interface Props {
  assetUrls: AssetUrl[]
}

export function Carousel({ assetUrls }: Props) {
  const [video, images] = separateAssets(assetUrls)

  return (
    <div
      className="flex relative w-full h-auto landscape:w-[100vh] landscape:h-full landscape:md:w-[60vh]"
      style={{ gridArea: 'carousel' }}
    >
      <div className="flex m-0 p-0 w-full bg-black portrait:max-h-[100vw]">
        <Swiper
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}
        >
          {video && (
            <SwiperSlide>
              <Video assetUrl={video} />
            </SwiperSlide>
          )}
          {images.map((url, index) => (
            <SwiperSlide key={index + 1}>
              <Image key={index} assetUrl={url} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
